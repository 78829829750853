
import { defineComponent } from "vue";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons-vue";

import { Admin } from "./index.ts";

export default defineComponent({
  setup() {
    return { ...Admin() };
  },
  components: {
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
});
